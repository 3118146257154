import React, { useContext } from 'react';
import './Documents.scss'
import { TranslateContext } from '../../App';
import LogoIcon from '../../assets/icons/LogoIcon';
import { Link } from 'react-router-dom';
import { FileModel } from '../File/File';
import File from '../File/File';

const Documents = () => {
    const translate = useContext(TranslateContext);

    const files: FileModel[] = [
        translate.documents.functionalDescription,
        translate.documents.setupManual,
        translate.documents.userManual,
        translate.documents.adminManual,
        translate.documents.watchManual,
    ];

    return (
        <div className='documents'>
            <div className='logo'>
                <Link to='/'>
                   <LogoIcon/>
                </Link>
            </div>

            <div className='documents-container'>
                <h4 className='documents-container-header'>
                    {translate.documents.heading}
                </h4>

                <div className='files-container'>
                    {files.map((file: FileModel) => <File file={file} key={file.name}/>)}
                </div>
            </div>
        </div>
    );
};

export default Documents;
