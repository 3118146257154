import React from 'react';
import './Footer.scss'
import LogoIcon from "../../assets/icons/LogoIcon";

const Footer = (props: {animation: boolean}) => {
    return (
        <div id='contacts' className='footer'>
            <div className='footer-logo'><LogoIcon/></div>
            <div className='footer-contacts'>
                <a href="mailto:info@solut-atomic.ru">info@solut-atomic.ru</a>
            </div>
        </div>
    );
};

export default Footer;
