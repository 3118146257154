import React, {useEffect, useState} from 'react';
import './Header.scss'
import LogoIcon from '../../assets/icons/LogoIcon';
import {Link} from 'react-scroll';
import BurgerImg from "../../assets/img/burger/BurgerImg";

const Header = () => {
    const [menu, setMenu] = useState(false)

    useEffect(() => {

    }, [])

    const isMobile = window.innerWidth < 980
    const showMenu = () => {
        setMenu(!menu)
    }
    return (
        <header className='header'>
            <div>
                <LogoIcon/>
            </div>
            {menu ? <div className='menu'  onClick={()=>setMenu(false)}>
                <div className={'menu-name'}>
                    <Link onClick={()=>showMenu()}
                        activeClass="active"
                        to="about-solut-atomic"
                        spy={true}
                        smooth={true}
                        offset={0}
                        duration={500}
                    >О КОМПАНИИ</Link>
                    <Link onClick={()=>showMenu()}
                        activeClass="active"
                        to="about-solution"
                        spy={true}
                        smooth={true}
                        offset={0}
                        duration={500}
                    >О РЕШЕНИИ</Link>
                    <Link onClick={()=>showMenu()}
                        activeClass="active"
                        to="payback"
                        spy={true}
                        smooth={true}
                        offset={0}
                        duration={500}
                    >СТОИМОСТЬ</Link>
                    <Link onClick={()=>showMenu()}
                        activeClass="active"
                        to="contacts"
                        spy={true}
                        smooth={true}
                        offset={0}
                        duration={500}
                    >КОНТАКТЫ</Link>
                </div>

            </div> : null}
            {isMobile ? <button className='burger-button' onClick={() => {
                showMenu()
            }
            }><BurgerImg/></button> : <div className='menu-name'>
                <Link
                    activeClass="active"
                    to="about-solut-atomic"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                >О КОМПАНИИ</Link>
                <Link
                    activeClass="active"
                    to="about-solution"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                >О РЕШЕНИИ</Link>
                <Link
                    activeClass="active"
                    to="payback"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                >СТОИМОСТЬ</Link>
                <Link
                    activeClass="active"
                    to="contacts"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                >КОНТАКТЫ</Link>
                {/*<NavLink to="/about-solut" activeClassName='link-active'>about us</NavLink>*/}
                {/*<NavLink to="/charts" activeClassName='link-active'>our system</NavLink>*/}
                {/*<NavLink to="/payback" activeClassName='link-active'>RETURN ON INVESTMENT</NavLink>*/}
                {/*<NavLink to="/contacts" activeClassName='link-active'>Contacts</NavLink>*/}
            </div>}

        </header>
    );
};

export default Header;
