import React, {useContext, useEffect, useLayoutEffect, useRef} from 'react';
import './AboutSolut.scss'
import {TranslateContext} from "../../App";

const AboutSolut = (props: {animation: boolean}) => {
    const translate = useContext(TranslateContext);

    const elRef = useRef<HTMLDivElement>(null);
    const about = useRef<HTMLDivElement>(null);
    const info = useRef<HTMLDivElement>(null);



    let observer = new IntersectionObserver(function (entries) {
        entries.forEach(function (entry) {
            const heightForRemove = entry.boundingClientRect.height / 6
            if (elRef.current && about.current && info.current) {
                if (entry.intersectionRatio > 0.2 && entry.boundingClientRect.y > 0) {
                    info.current.style.opacity = '1'
                }

                if (entry.intersectionRatio < 0.8) {
                    elRef.current.style.transform = `translate3d(-1000px,0,0)`

                    info.current.style.opacity = '0'
                }
                if (entry.boundingClientRect.y < entry.boundingClientRect.height/3) {
                    elRef.current.style.transform = `translate3d(0,0,0)`
                    about.current.style.position = `sticky`
                }
                if (entry.boundingClientRect.y < -heightForRemove) {
                    elRef.current.style.transform = `translate3d(0,-200vh,0)`
                }
            }
        });
    });


    useLayoutEffect(() => {
        if (props.animation){
            let elShoe = document.querySelector('.about');
            // @ts-ignore
            observer.observe(elShoe);
        }
    })
    const heightDesktop = document.documentElement.clientHeight;
    return (
        <div id='about-solut-atomic' ref={about} className={props.animation ? 'about' : 'about non-animation'}>
            <div className='about-wrapper'>
                <div className = {props.animation ? 'about-img' : 'about-img non-animation'} ref={elRef}>
                    <img src='/images/about-solut-atomic.jpeg' alt=""/>
                </div>
                <div ref={info} className = {props.animation ? 'info' : 'info non-animation'}>
                    <h2 className='name solut'>SOLUT</h2>
                    <h2 className='name atomic'>ATOMIC</h2>
                    <p className='description'><b>{translate.aboutSolut.preDescription}</b></p>
                    <p className='description'>{translate.aboutSolut.description}</p>
                </div>
            </div>
        </div>
    );
};

export default AboutSolut;
