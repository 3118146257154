import React from 'react';
import './File.scss'

export type FileModel = {
    name: string;
    link: string;
}

const File = (props: { file: FileModel }) => {
    return (
        <a className='file-link' href={props.file.link} target='_blank'>
            <div className='file'>
                <div className='file-name'>
                    <img src='/images/pdf.png' alt='pdf'/>
                    <span>{props.file.name}</span>
                </div>

                <img className='link-icon' src='/images/link.png' alt='link'/>
            </div>
        </a>
    );
};

export default File;
