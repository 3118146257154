import React from 'react';

const LogoIcon = () => {
    return (
        <svg width="320" height="120" viewBox="0 0 739 236" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_203_69" maskUnits="userSpaceOnUse" x="0" y="0" width="430" height="236">
                <path d="M0 -1.64509e-05H430V236H0V-1.64509e-05Z" fill="white"/>
            </mask>
            <g mask="url(#mask0_203_69)">
                <path
                    d="M373.51 120.228C366.457 120.228 360.719 125.959 360.719 133.002C360.719 137.809 363.394 142 367.333 144.179V186.321H13.0425V65.1827H365.079L358.37 72.076L367.229 80.6763L388.312 59.0154L367.233 37.3398L358.37 45.9361L365.088 52.8441H0.6875V198.66H379.688V144.179C383.627 142 386.301 137.809 386.301 133.002C386.301 125.959 380.564 120.228 373.51 120.228Z"
                    fill="#00ABBD"/>
                <path
                    d="M267.486 114.175C267.486 107.933 267.497 101.69 267.47 95.4475C267.468 94.7882 267.62 94.5973 268.303 94.604C272.07 94.64 275.838 94.6347 279.605 94.608C280.204 94.604 280.432 94.6934 280.431 95.3781C280.405 107.123 280.455 118.866 280.397 130.611C280.355 139.314 277.016 146.624 270.251 152.086C264.689 156.58 258.129 158.307 250.967 157.632C245.654 157.131 240.859 155.229 236.805 151.841C231.656 147.536 228.477 141.982 227.373 135.302C227.084 133.552 226.992 131.796 226.992 130.028C226.995 118.511 227.001 106.996 226.976 95.4795C226.975 94.7615 227.152 94.596 227.859 94.604C231.599 94.6427 235.338 94.64 239.077 94.6053C239.748 94.5987 239.911 94.7722 239.908 95.4381C239.85 107.61 239.807 119.782 239.783 131.953C239.769 138.019 243.511 143.284 249.086 145.117C257.622 147.922 266.29 142.478 267.43 133.584C267.506 132.994 267.484 132.389 267.484 131.791C267.488 125.918 267.486 120.047 267.486 114.175Z"
                    fill="#FBFBFB"/>
                <path
                    d="M62.4401 93.6338C69.4629 93.7606 75.5956 95.7799 80.5737 100.674C82.2723 102.344 83.5298 104.339 84.4038 106.57C84.599 107.068 84.5656 107.304 84.0564 107.555C80.4882 109.312 76.9334 111.092 73.3839 112.884C73.003 113.077 72.8627 113.03 72.7598 112.62C71.7241 108.523 68.7492 106.657 64.8723 105.95C62.3679 105.493 59.8769 105.681 57.47 106.606C56.3635 107.032 55.3826 107.651 54.5553 108.494C53.0358 110.04 52.8982 111.914 54.1517 113.673C55.2275 115.181 56.743 116.014 58.4817 116.528C62.6766 117.768 66.8943 118.933 71.0479 120.296C75.6959 121.821 79.6596 124.404 82.5382 128.461C87.38 135.286 86.7746 143.796 81.0281 149.877C76.8198 154.331 71.5998 156.731 65.5713 157.441C59.2742 158.182 53.3004 157.171 47.8533 153.809C43.7625 151.287 40.6821 147.88 39.0491 143.275C38.8018 142.577 38.8285 142.237 39.6197 141.904C43.2066 140.4 46.7628 138.821 50.3122 137.232C50.8816 136.977 51.0139 137.143 51.1408 137.654C52.5828 143.461 57.6478 146.101 63.0201 145.712C65.4163 145.539 67.6681 144.935 69.6019 143.414C72.8867 140.829 72.9135 136.73 69.678 134.066C67.9447 132.64 65.8252 132.18 63.7564 131.573C60.0239 130.477 56.2098 129.658 52.5561 128.307C48.9117 126.958 45.7765 124.831 43.3082 121.802C38.1283 115.448 38.4918 106.931 44.1755 101.005C48.1406 96.8716 53.0559 94.6842 58.6741 93.9061C59.9798 93.7259 61.2935 93.7713 62.4401 93.6338Z"
                    fill="#FBFBFB"/>
                <path
                    d="M169.512 125.747C169.512 115.683 169.522 105.619 169.49 95.5551C169.488 94.7449 169.729 94.5968 170.477 94.6048C174.158 94.6422 177.841 94.6449 181.523 94.6021C182.274 94.5928 182.459 94.773 182.457 95.5297C182.432 111.637 182.445 127.745 182.42 143.852C182.419 144.701 182.574 144.981 183.502 144.977C194.407 144.94 205.313 144.961 216.218 144.937C216.945 144.936 217.182 145.071 217.172 145.854C217.122 149.275 217.14 152.697 217.161 156.118C217.165 156.658 217.112 156.904 216.453 156.902C201.037 156.881 185.621 156.881 170.205 156.902C169.537 156.904 169.501 156.642 169.502 156.11C169.516 145.989 169.512 135.867 169.512 125.747Z"
                    fill="#FBFBFB"/>
                <path
                    d="M321.276 131.826C321.276 139.893 321.265 147.959 321.293 156.026C321.296 156.74 321.122 156.912 320.411 156.904C316.673 156.864 312.934 156.868 309.195 156.901C308.532 156.908 308.359 156.744 308.361 156.071C308.381 139.91 308.369 123.749 308.398 107.587C308.4 106.738 308.171 106.583 307.369 106.59C302.089 106.629 296.809 106.593 291.529 106.63C290.791 106.634 290.653 106.423 290.661 105.733C290.699 102.285 290.69 98.8359 290.667 95.3873C290.663 94.8361 290.734 94.6052 291.38 94.6065C306.964 94.6265 322.548 94.6252 338.132 94.6065C338.751 94.6065 338.872 94.7853 338.867 95.3672C338.839 98.8733 338.837 102.379 338.868 105.884C338.875 106.498 338.692 106.626 338.108 106.623C332.8 106.601 327.49 106.63 322.182 106.589C321.393 106.582 321.257 106.816 321.26 107.542C321.285 115.636 321.276 123.732 321.276 131.826Z"
                    fill="#FBFBFB"/>
                <path
                    d="M125.704 144.946C115.048 144.942 106.523 136.388 106.54 125.718C106.557 115.054 115.14 106.508 125.776 106.562C136.39 106.615 144.984 115.246 144.966 125.832C144.947 136.329 136.26 144.95 125.704 144.946ZM125.903 93.736C107.751 93.728 93.8483 108.386 93.8242 125.708C93.8002 143.076 107.727 157.777 125.899 157.771C144.074 157.766 157.906 143.001 157.942 125.755C157.909 108.51 144.08 93.7427 125.903 93.736Z"
                    fill="#FBFBFB"/>
            </g>
            <path
                d="M394.739 157L415.88 94.795H431.888L453.029 157H437.369L433.541 143.95H414.14L410.399 157H394.739ZM417.707 131.77H429.974L426.494 119.677C425.363 115.762 423.971 108.976 423.971 108.976H423.797C423.797 108.976 422.405 115.762 421.274 119.677L417.707 131.77Z"
                fill="#E03227"/>
            <path d="M467.854 157V107.845H448.975V94.795H501.958V107.845H483.079V157H467.854Z" fill="#E03227"/>
            <path
                d="M504.758 125.506C504.758 107.671 517.982 93.751 537.296 93.751C556.61 93.751 569.834 107.671 569.834 125.506C569.834 143.776 556.61 158.044 537.296 158.044C517.982 158.044 504.758 143.776 504.758 125.506ZM520.505 125.506C520.505 136.12 527.465 144.124 537.296 144.124C547.127 144.124 554.087 136.12 554.087 125.506C554.087 115.327 547.127 107.671 537.296 107.671C527.465 107.671 520.505 115.327 520.505 125.506Z"
                fill="#E03227"/>
            <path
                d="M577.082 157L582.215 94.795H598.745L607.793 121.069C609.185 125.071 610.925 131.335 610.925 131.335H611.099C611.099 131.335 612.839 125.071 614.231 121.069L623.279 94.795H639.809L644.855 157H629.63L627.716 128.986C627.368 124.288 627.716 118.546 627.716 118.546H627.542C627.542 118.546 625.454 125.071 624.062 128.986L617.537 146.995H604.487L597.962 128.986C596.57 125.071 594.482 118.546 594.482 118.546H594.308C594.308 118.546 594.656 124.288 594.308 128.986L592.394 157H577.082Z"
                fill="#E03227"/>
            <path d="M655.558 157V94.795H670.783V157H655.558Z" fill="#E03227"/>
            <path
                d="M680.544 125.767C680.544 107.671 693.942 93.751 713.169 93.751C721.521 93.751 730.395 96.535 736.485 102.538L729.438 114.022C725.436 110.542 719.433 107.671 713.865 107.671C701.424 107.671 696.291 116.458 696.291 125.419C696.291 134.641 701.859 144.124 713.865 144.124C720.042 144.124 726.132 140.731 730.395 136.468L738.225 147.604C732.744 153.259 724.566 158.044 713.169 158.044C693.159 158.044 680.544 144.211 680.544 125.767Z"
                fill="#E03227"/>
        </svg>
    );
};

export default LogoIcon;
